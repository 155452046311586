export function initFileUpload() {
  const fileInput = document.getElementById("files");
  const filesLabel = document.getElementById("files-label");
  const filesSpan = filesLabel.querySelector(".files-wrapper__text");
  let allFiles = [];

  function updateFileDisplay() {
    // Clear the previous content
    filesSpan.innerHTML = "";

    if (allFiles.length === 0) {
      filesSpan.textContent =
        "Dodajte sve nalaze koji lekaru mogu biti korisni";
    } else {
      allFiles.forEach((file, index) => {
        const fileSpan = document.createElement("span");
        fileSpan.textContent = file.name;

        const removeButton = document.createElement("button");
        removeButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
              <g id="Group 580661">
                <path id="Vector" d="M12.5586 0.629883L0.558594 12.6299" stroke="#081249" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Vector_2" d="M0.558594 0.629883L12.5586 12.6299" stroke="#081249" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
          `;
        removeButton.className = "remove-file";

        removeButton.addEventListener("click", function (event) {
          event.preventDefault();
          // Remove file from allFiles array
          allFiles.splice(index, 1);
          updateFileDisplay(); // Update the display after removal
        });

        fileSpan.appendChild(removeButton);
        filesSpan.appendChild(fileSpan);
      });
    }
  }

  fileInput.addEventListener("change", function (e) {
    // Add new files to the allFiles array
    allFiles = allFiles.concat(Array.from(fileInput.files));
    updateFileDisplay(); // Update the display with the new file list
  });
}
