export function initDiseaseScroller() {
    const urlParams = new URLSearchParams(window.location.search);
    const drawerId = urlParams.get("drawer_id");
  
    if (drawerId) {
      const targetElement = document.querySelector(
        '.accordion[data-drawer-id="' + drawerId + '"]'
      );
      if (targetElement) {
        setTimeout(() => {
          const yOffset =
            targetElement.getBoundingClientRect().top + window.scrollY - 112;
          window.scrollTo({ top: yOffset });
  
          const nextBtn = targetElement.querySelector(".next-btn");
          if (nextBtn) {
            nextBtn.dispatchEvent(new Event("click"));
          } else {
            console.error("Next button not found.");
          }
        }, 100);
      }
    }
  
    document
      .querySelectorAll('.single-disease a[href^="#"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();
  
          const targetId = this.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            const offset = -20; // Adjust this value based on your fixed header height
            const targetPosition = targetElement.offsetTop - offset;
  
            window.scrollTo({
              top: targetPosition,
              behavior: "smooth",
            });
          }
        });
      });
}