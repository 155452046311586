import { initAccordions } from "./accordions";
import { initAjaxSearch } from "./ajaxSearch";
import { initCheckLoadedVideos } from "./checkLoadedVideos";
import { initDiseaseScroller } from "./diseaseScroller";
import { initDropdowns } from "./dropdowns";
import { initFileUpload } from "./fileUpload";
import { initFormHandling } from "./formHandling";
import { initSwipers, updateDoctorsSwiper } from "./swiperInitialization";
import { initToggleContent } from "./toggleContent";
import { initVideoHomepage } from "./videoHomepage";

document.addEventListener("DOMContentLoaded", () => {
  initCheckLoadedVideos();
  initSwipers();
  initToggleContent();
  initFormHandling();
  initDropdowns();
  initAccordions();
  initDiseaseScroller();
  initFileUpload();
  initVideoHomepage();

  const showMoreButtons = document.querySelectorAll(".show-more");

  showMoreButtons?.forEach((btn) => {
    if (btn) {
      btn.addEventListener("click", function () {
        const hiddenItems = document.querySelectorAll(".hidden-item");
        const isExpanded = btn.getAttribute("data-expanded") === "true";
        const showMoreText = btn.querySelector(".show-more-text");
        const showLessText = btn.querySelector(".show-less-text");

        hiddenItems.forEach((item) => {
          item.classList.toggle("visible", !isExpanded);
        });

        showMoreText.style.display = isExpanded ? "block" : "none";
        showLessText.style.display = isExpanded ? "none" : "block";

        btn.setAttribute("data-expanded", isExpanded ? "false" : "true");
      });
    }
  });

  const newsItem = document.querySelectorAll(
    ".single-center__body__content-wrapper__actual__items-wrapper__item"
  );

  newsItem?.forEach((item) => {
    const toggleButton = item.querySelector(".toggle-text");
    const shortText = item.querySelector(".news-text-short");
    const fullText = item.querySelector(".news-text-full");

    toggleButton?.addEventListener("click", function () {
      if (fullText.style.display === "none") {
        fullText.style.display = "block";
        shortText.style.display = "none";
        toggleButton.textContent = "Show Less";
      } else {
        fullText.style.display = "none";
        shortText.style.display = "block";
        toggleButton.textContent = "Show More";
      }
    });
  });

  const floatingButtons = document.querySelector(".floating-buttons");
  let hideTimer;

  function showButtons() {
    floatingButtons?.classList.add("visible");

    if (window.scrollY < 100) {
      clearTimeout(hideTimer); // Ensure buttons remain visible at the top
    } else {
      clearTimeout(hideTimer); // Clear any existing timer for non-top positions

      // Set a new timer to hide the buttons after 2 seconds
      hideTimer = setTimeout(function () {
        floatingButtons?.classList.remove("visible");
      }, 2000);
    }
  }

  showButtons();

  // Add event listeners for scroll and click events
  document.addEventListener("scroll", showButtons);
  // document.addEventListener("click", showButtons);

  const backBtnSearch = document.querySelector(
    ".hidden-menu__menu-wrapper__search-results .back-btn"
  );

  backBtnSearch?.addEventListener("click", () => {
    const searchWrapper = document.querySelector(".hidden-menu__menu-wrapper");

    searchWrapper.classList.remove("search");
  });

  function removeActiveAndVisibleClasses() {
    // Remove active class from menu items
    document.querySelectorAll("li.active").forEach((item) => {
      item.classList.remove("active");
    });

    // Remove visible class from center lists
    document.querySelectorAll("ul.visible").forEach((item) => {
      item.classList.remove("visible");
    });
  }

  const centerButtons = document.querySelectorAll(
    ".doctors-team__header__filters button"
  );

  let allDoctors = [];
  document.querySelectorAll(".doctors").forEach((doctor) => {
    // Store necessary details of each doctor
    let doctorInfo = {
      element: doctor.outerHTML, // Store the HTML representation of the doctor element
      centerIds: JSON.parse(doctor.getAttribute("data-center-ids")), // Store associated center IDs
      centerAnesthesiologist: JSON.parse(doctor.getAttribute("data-center-anesthesiologist")), // Store associated center IDs
    };
    allDoctors.push(doctorInfo);
  });

  function filterDoctors(centerId) {
    const filteredDoctors = allDoctors.filter(
      (doctor) =>
       centerId ==="all" || doctor.centerIds.includes(Number(centerId))
    );
    updateDoctorsSwiper(filteredDoctors);
  }

  function filterAnesthesiologist() {
    const filteredAnesthesiologist = allDoctors.filter(
      (doctor) =>
        {
        
          return Number(doctor.centerAnesthesiologist);
        }
    );

    updateDoctorsSwiper(filteredAnesthesiologist)
  }

  centerButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const centerId = this.getAttribute("data-center-id"); // Get the center ID from the button
      if (centerId !== "anesthesiologist") {
        filterDoctors(centerId); // Filter doctors based on the selected center
      } else {
        filterAnesthesiologist();
      }
    });
  });

  const adultsMenuItem = document.querySelector(".category.adults");
  const kidsMenuItem = document.querySelector(".category.kids");
  const firstColumnItems = document.querySelectorAll(
    ".hidden-menu__menu-wrapper__content-wrapper__col:first-child li"
  );

  const secondColumnItems = document.querySelectorAll(
    ".hidden-menu__menu-wrapper__content-wrapper__col.centers li"
  );

  const adultsCenterList = document.querySelector(".centers-for-adults");
  const kidsCenterList = document.querySelector(".centers-for-kids");

  function hideCenterLists() {
    adultsCenterList.classList.remove("visible");
    kidsCenterList.classList.remove("visible");
    adultsMenuItem.classList.remove("active");
    kidsMenuItem.classList.remove("active");
  }

  firstColumnItems?.forEach((item) => {
    item.addEventListener("mouseenter", function () {
      secondColumnItems.forEach((sci) => {
        sci.classList.remove("active");
      });
      document
        .querySelectorAll(".drawers-and-diseases.visible")
        .forEach((ul) => {
          ul.classList.remove("visible");
        });
      if (!item.classList.contains("category")) {
        item.classList.add("active");
        hideCenterLists();
      }
    });

    item.addEventListener("mouseleave", function () {
      if (!item.classList.contains("category")) {
        item.classList.remove("active");
      }
    });
  });

  const handleEventListenersForSecondColumn = () => {
    [...secondColumnItems]?.forEach((item) => {
      const svg = item?.querySelector("span");

      svg?.addEventListener("click", (e) => {
        secondCol.classList.remove("active");

        thirdCol.classList.add("active");
      });
    });
  };

  const adultsMenuItemHandler = () => {
    adultsMenuItem.classList.add("active");
    kidsMenuItem.classList.remove("active");
    kidsCenterList.classList.remove("visible");
    adultsCenterList.classList.add("visible");

    setTimeout(() => {
      firstCol?.classList.remove("active");
      bottom?.classList.add("hidden");
    }, 200);

    setTimeout(() => {
      secondCol?.classList.add("active");
      handleEventListenersForSecondColumn();
    }, 300);
  };

  // Add event listener for hovering over the adults menu item
  adultsMenuItem?.addEventListener("mouseenter", function () {
    adultsMenuItemHandler();
  });

  adultsMenuItem?.addEventListener("mouseover", function () {
    adultsMenuItemHandler();
  });

  // adultsMenuItem?.addEventListener("touchstart", function () {
  //   adultsMenuItemHandler();
  // });

  const firstCol = document.querySelectorAll(
    ".hidden-menu__menu-wrapper__content-wrapper__col"
  )[0];
  const secondCol = document.querySelectorAll(
    ".hidden-menu__menu-wrapper__content-wrapper__col"
  )[1];
  const secondColBackBtn = secondCol?.querySelector(".back-btn");

  const thirdCol = document.querySelectorAll(
    ".hidden-menu__menu-wrapper__content-wrapper__col"
  )[2];

  const thirdColBackBtn = thirdCol?.querySelector(".back-btn");

  thirdColBackBtn?.click();

  const thirdColBackBtnHandler = () => {
    secondCol?.classList.add("active");
    thirdCol?.classList.remove("active");
  };

  const secondColBackBtnHandler = () => {
    firstCol?.classList.add("active");
    secondCol?.classList.remove("active");
    bottom?.classList.remove("hidden");
  };

  secondColBackBtn?.addEventListener("click", secondColBackBtnHandler);
  thirdColBackBtn?.addEventListener("click", thirdColBackBtnHandler);

  const bottom = document.querySelector(".hidden-menu__menu-wrapper__bottom");

  const kidsMenuItemHandler = () => {
    kidsMenuItem.classList.add("active");
    adultsMenuItem.classList.remove("active");
    adultsCenterList.classList.remove("visible");
    kidsCenterList.classList.add("visible");

    setTimeout(() => {
      firstCol?.classList.remove("active");
      bottom?.classList.add("hidden");
    }, 200);
    setTimeout(() => {
      secondCol?.classList.add("active");
      handleEventListenersForSecondColumn();
    }, 300);
  };

  kidsMenuItem?.addEventListener("mouseenter", function () {
    kidsMenuItemHandler();
  });

  kidsMenuItem?.addEventListener("mouseover", function () {
    kidsMenuItemHandler();
  });

  secondColumnItems?.forEach((item) => {
    item.addEventListener("mouseenter", function () {
      secondColumnItems?.forEach((item) => {
        item.classList.remove("active");
      });
      item.classList.add("active");

      document
        .querySelectorAll(".drawers-and-diseases.visible")
        .forEach((ul) => {
          ul.classList.remove("visible");
        });

      const centerId = item.getAttribute("data-center-id");

      // Find the corresponding ul element and show it
      const centerList = document.querySelector(
        `.drawers-and-diseases[data-center-id="${centerId}"]`
      );
      if (centerList) {
        centerList.classList.add("visible");
      }
    });
  });

  const header = document.querySelector("header");
  const menuBtn = document.querySelector(".menu-btn");
  const appointmentBtn = document.querySelector("#appointment-btn");
  const menuAppointmentBtns = document.querySelectorAll(
    ".appointment-btn-menu"
  );
  const floatingAppointmentBtn = document.querySelector(
    ".floating-appointment"
  );
  const closeMenuBtn = document.querySelector(".hidden-menu .close-btn");
  const closeFormBtn = document.querySelector(".hidden-form .close-btn");
  const hiddenMenuListItems = document.querySelectorAll(".hidden-menu li");

  const svgMarkup = `
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M0.375 7.5H13.375M7.375 0.875L14 7.5L7.375 14.125" stroke="#081249" stroke-width="1.3"/>
      </svg>
  `;

  const svgElement = new DOMParser().parseFromString(
    svgMarkup,
    "text/xml"
  ).documentElement;

  [adultsMenuItem, kidsMenuItem, ...secondColumnItems]?.forEach(function (
    item
  ) {
    const svgContainer = document.createElement("span");
    svgContainer.classList.add("svg-container");
    svgContainer.innerHTML = svgMarkup;
    item.appendChild(svgContainer);
  });

  const handleFormOpen = () => {
    header.classList.toggle("form-opened");
    document.body.classList.add("menu-opened");
    floatingButtons?.classList.remove("visible");
  };

  const handleMenuOpen = () => {
    header.classList.toggle("opened");
    removeActiveAndVisibleClasses();
    document.body.classList.add("menu-opened");
    floatingButtons?.classList.remove("visible");
  };

  menuBtn.addEventListener("click", handleMenuOpen);
  appointmentBtn.addEventListener("click", handleFormOpen);
  floatingAppointmentBtn?.addEventListener("click", handleFormOpen);
  menuAppointmentBtns?.forEach((btn) => {
    btn.addEventListener("click", () => {
      handleFormOpen();
    });
  });

  closeFormBtn.addEventListener("click", () => {
    header.classList.remove("form-opened");
    if (!header.classList.contains("opened")) {
      document.body.classList.remove("menu-opened");
    }
  });

  closeMenuBtn.addEventListener("click", () => {
    header.classList.remove("opened");
    document.body.classList.remove("menu-opened");
    removeActiveAndVisibleClasses();

    setTimeout(() => {
      document
        .querySelector(".hidden-menu__menu-wrapper")
        .classList.remove("search");
      document.querySelector("#pretraga").value = "";
      // firstMenuTouchedHandlerDefault();
    }, 300);
  });
});

initAjaxSearch();
