export function initCheckLoadedVideos() {
  const videos = document.querySelectorAll("video.loading-video");
  let loadedVideos = 0;
  const totalVideos = videos.length;
  const loaderWrapper = document.querySelector(".loader-wrapper");
  const progressBar = document.querySelector(".progress-bar"); // Assuming you have a progress bar element

  if (progressBar) {
    // document.body.style.overflow = "hidden";
    progressBar.style.width = `100%`;
  }


  function updateLoaderProgress() {
    if (loadedVideos >= totalVideos) {
        hideLoader();
    }
  }

  function hideLoader() {
    if (loaderWrapper) {
      loaderWrapper.classList.remove("active");
    }
  }

  // Fallback to hide loader if videos take too long
  setTimeout(hideLoader, 10000);

  // Attach event listeners to videos
  videos.forEach((video) => {
    video.addEventListener("loadeddata", () => {
      loadedVideos++;
      updateLoaderProgress();
    });

    // You might still want to keep these events
    if (video.autoplay) {
      video.addEventListener(
        "playing",
        () => {
          loadedVideos++;
          updateLoaderProgress();
        },
        { once: true }
      );
    } else {
      video.addEventListener(
        "canplay",
        () => {
          loadedVideos++;
          updateLoaderProgress();
        },
        { once: true }
      );
    }
  });
}
