export function initToggleContent() {
    const toggleBtn = document.getElementById("toggleContent");
    const btnText = toggleBtn?.querySelector("span");
    const btnSVG = toggleBtn?.querySelector("svg");
    const contentWrapper = document.querySelector(
      ".about-us-homepage__content-wrapper__top__left__inner__content-wrapper"
    );
    let isExpanded = false;
  
    toggleBtn?.addEventListener("click", function () {
      const userLang = document.documentElement.lang;
      if (!isExpanded) {
        contentWrapper.style.maxHeight = contentWrapper.scrollHeight + "px";

        btnText.textContent = userLang === "en" ? "close" : "zatvori";
        btnSVG.style.display = "inline";
        toggleBtn.classList.remove("not-opened");
      } else {
        contentWrapper.style.maxHeight = "400px";
        if (window.innerWidth >= 1280) {
          contentWrapper.style.maxHeight = "362px";
        }
        if (window.innerWidth >= 1500) {
          contentWrapper.style.maxHeight = "362px";
        }
        if (window.innerWidth >= 1900) {
          contentWrapper.style.maxHeight = "310px";
        }
        toggleBtn.classList.add("not-opened");
        btnText.textContent = userLang === "en" ? "Read more" : "Pročitaj više";
        btnSVG.style.display = "none";
      }
      isExpanded = !isExpanded;
    });
}