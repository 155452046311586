export function initDropdowns() {
  const dropdowns = document.querySelectorAll(".doctors-dropdown");

  dropdowns.forEach((dd) => {
    const ddHeader = dd.querySelector(".doctors-dropdown__header");
    const ddBody = dd.querySelector(".doctors-dropdown__body");
    const doctors = dd.querySelectorAll(".content > div");

    ddHeader?.addEventListener("click", () => {
      dd.classList.toggle("opened");

      if (dd.classList.contains("opened")) {
        ddBody.style.maxHeight = ddBody.scrollHeight + "px";
        setTimeout(() => {
          ddBody.style.overflowY = "auto";
        }, 350);
      } else {
        ddBody.style.maxHeight = 0;
        ddBody.style.overflowY = "hidden";
      }
    });

    doctors.forEach((doctor) => {
      doctor.addEventListener("click", () => {
        const selectedDoctorName = doctor.textContent.trim();
        const headerText = ddHeader.querySelector(
          "span.doctors-dropdown__header__text"
        );

        if (headerText) {
          headerText.innerHTML = selectedDoctorName;
        }

        // Close the dropdown after selecting a doctor
        dd.classList.remove("opened");
        ddBody.style.maxHeight = 0;
        ddBody.style.overflowY = "hidden";
      });
    });
  });
}
