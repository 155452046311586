export function initVideoHomepage() {
  // Select all video containers
  const videoContainers = document.querySelectorAll(".video-wrapper");

  videoContainers.forEach((container) => {
    let timeoutId;

    if (container.dataset.initialized === "true") return;
    const video = container.querySelector(".custom-controls-video");
    const image = container.querySelector("img");

    const pauseSvg = container.querySelector("svg.pause-svg");
    const playSvg = container.querySelector("svg.play-svg");

    const videoChoises = container.querySelector(".video-choises");

    container.dataset.initialized = true;
    
    const playButton = container.querySelector(
      ".controls .play"
    );
    const pauseButton = container.querySelector(
      ".controls .pause"
    );
    const muteButton = container.querySelector(
      ".controls .mute"
    );
    const volumeWrapper = container.querySelector(
      ".controls .volume-wrapper"
    );
    const volumeSlider = container.querySelector(
      ".controls .volume-slider"
    );

    const volumeIcons = {
      mute: volumeWrapper?.querySelector(".mute"),
      low: volumeWrapper?.querySelector(".low"),
      medium: volumeWrapper?.querySelector(".medium"),
      high: volumeWrapper?.querySelector(".high"),
    };

    function togglePlayPause() {
      if (video && video.paused) {
        video.play();
        playButton?.classList.remove("visible");
        pauseButton?.classList.add("visible");
      } else {
        video?.pause();
        pauseButton?.classList.remove("visible");
        playButton?.classList.add("visible");
      }
    }
    
    if (videoChoises) {

      videoChoises.querySelectorAll("& > div").forEach((choise, index) => {
        choise.addEventListener("click", () => {
          videoContainers.forEach((videoContainer) => {
            videoContainer.style.display = "none";

            const video = videoContainer.querySelector("video");
            const playButton = videoContainer.querySelector(
              ".controls .play"
            );
            const pauseButton = videoContainer.querySelector(
              ".controls .pause"
            );

            video.pause();
            video.currentTime = 0;
            playButton?.classList.add("visible");
            pauseButton?.classList.remove("visible");
          })

          videoContainers[index].style.display = "block";
        })
      })
    }

    // Play/Pause video
    playButton?.addEventListener("click", togglePlayPause);
    pauseButton?.addEventListener("click", togglePlayPause);

    if (image && !image.classList.contains("single-doc-img")) {
      container?.addEventListener("click", togglePlayPause);
    } else {
      image?.addEventListener("click", togglePlayPause);
      video?.addEventListener("click", togglePlayPause);
    }

    video?.addEventListener("play", () => {
      if (pauseSvg && playSvg) {
        pauseSvg.style.display = "block";
        playSvg.style.display = "none";
      }

      if (image) {
        video.style.display = "block";
        image.style.display = "none";
      }
    });

    function hideControls() {
      if (pauseSvg && playSvg) {
        if (video?.paused) {
          playSvg.style.display = "block"; // Ensure playSvg is visible when video is paused
        } else {
          pauseSvg.style.display = "none"; // Hide pauseSvg when video is playing
        }
      }
    }

    function showControls() {
      // Adjust SVG display based on video state
      if (pauseSvg && playSvg) {
        if (video?.paused) {
          pauseSvg.style.display = "none";
          playSvg.style.display = "block";
        } else {
          pauseSvg.style.display = "block";
          playSvg.style.display = "none";
        }
      }
    }

    function showImage() {
      if (image) {
        video.style.display = "none";
        image.style.display = "block";
      }

      if (pauseSvg && playSvg) {
        playSvg.style.display = "block";
        pauseSvg.style.display = "none";
      }

    }

    video?.addEventListener("pause", showImage);
    video?.addEventListener("ended", showImage);

    container?.addEventListener("mousemove", () => {
      clearTimeout(timeoutId);
      showControls();
      
      timeoutId = setTimeout(hideControls, 2000);
    })

    container?.addEventListener("touchstart", () => {
      clearTimeout(timeoutId);
      showControls();
      
      timeoutId = setTimeout(hideControls, 2000);
    })

    // Mute video
    muteButton?.addEventListener("click", () => {
      video.muted = !video.muted;
    });

    function updateVolumeIcon(volume) {
      Object.keys(volumeIcons).forEach((key) => {
        volumeIcons[key]?.classList.remove("visible"); // Hide all icons
      });

      if (volume === 0) {
        volumeIcons.mute?.classList.add("visible");
      } else if (volume > 0 && volume <= 0.33) {
        volumeIcons.low?.classList.add("visible");
      } else if (volume > 0.33 && volume <= 0.66) {
        volumeIcons.medium?.classList.add("visible");
      } else {
        volumeIcons.high?.classList.add("visible");
      }
    }

    // Toggle volume slider display
    Object.values(volumeIcons)?.forEach((icon) => {
      icon?.addEventListener("click", () => {
        volumeSlider.style.display =
          volumeSlider.style.display === "none" ? "block" : "none";
      });
    });

    // Adjust volume
    volumeSlider?.addEventListener("input", () => {
      const volume = volumeSlider.value / 100; // Convert range value (0-100) to a fraction (0-1)
      video.volume = volume;
      updateVolumeIcon(volume);
    });

    // Initialize volume icon based on the current volume
    if (video) updateVolumeIcon(video.volume);

    // Reset play button when video ends
    video?.addEventListener("ended", () => {
      pauseButton.classList.remove("visible");
      playButton.classList.add("visible");
    });
  });
}
