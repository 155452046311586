import { isValidEmail, isValidPhone } from "./utils";

export function initFormHandling() {
  const submitBtn = document.getElementById("submit-btn");

  const userLang = document.documentElement.lang;

  const errorMessages = {
    en: {
      name: "Please enter your name.",
      email: "Please enter a valid email address.",
      lastName: "Please enter your last name.",
      contactPhone: "Please enter a valid contact phone number.",
    },
    sr: {
      name: "Molimo unesite vaše ime.",
      email: "Molimo unesite važeću adresu e-pošte.",
      lastName: "Molimo unesite vaše prezime.",
      contactPhone: "Molimo unesite važeći kontakt telefon.",
    },
  };

  submitBtn.addEventListener("click", function () {
    // Capture field values
    submitBtn.disabled = true;
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const lastName = document.getElementById("last-name").value.trim();
    const contactPhone = document.getElementById("contact-phone").value.trim();
    const howCanWeHelp = document.getElementById("how-can-we").value;
    const chosenDoctor = document.querySelector(
      ".doctors-dropdown__header__text"
    ).textContent;
    const fileInput = document.getElementById("files");

    const contactMethodCheckboxes = document.querySelectorAll(
      'input[name="contactMethod"]:checked'
    );
    let contactMethods = [];
    contactMethodCheckboxes?.forEach((checkbox) => {
      contactMethods.push(checkbox.value);
    });

    // Define error messages
    const errors = {
      name: name ? "" : errorMessages[userLang].name,
      email: isValidEmail(email) ? "" : errorMessages[userLang].email,
      lastName: lastName ? "" : errorMessages[userLang].lastName,
      contactPhone: isValidPhone(contactPhone)
        ? ""
        : errorMessages[userLang].contactPhone
    };

    // Show or hide errors
    Object.keys(errors).forEach((field) => {
      if (errors[field]) {
        showError(field, errors[field]);
      } else {
        hideError(field);
      }
    });

    // Check if all errors are resolved
    const allErrorsResolved = Object.keys(errors).every((field) => {
      const errorElement = document.getElementById(field + "-error");
      return errorElement && errorElement.classList.contains("hidden");
    });

    if (!allErrorsResolved) {
      submitBtn.disabled = false;
      return;
    }

    const cf7NameField = document.querySelector('[name="your-name"]');
    const cf7EmailField = document.querySelector('[name="your-email"]');
    const cf7LastNameField = document.querySelector('[name="your-last-name"]');
    const cf7PhoneField = document.querySelector('[name="your-phone"]');
    const cf7HelpField = document.querySelector('[name="how-can-we-help"]');
    const cf7DoctorField = document.querySelector('[name="chosen-doctor"]');
    const cf7Files = document.querySelector('[name="files-wc7[]"]');
    const cf7ContactMethod = document.querySelector('[name="how-to-contact"]');

    cf7NameField.value = name;
    cf7EmailField.value = email;
    cf7LastNameField.value = lastName;
    cf7PhoneField.value = contactPhone;
    cf7HelpField.value = howCanWeHelp;
    cf7DoctorField.value = chosenDoctor;
    cf7ContactMethod.value = contactMethods?.join(", ") ?? "";
    cf7Files.files = fileInput.files;

    const cf7SubmitButton = document.querySelector(
      '#contact-form-container .wpcf7-form input[type="submit"]'
    );

    if (cf7SubmitButton) {
      cf7SubmitButton.click();
      submitBtn.classList.add("form-submitting");
    } else {
      // Log error or show message if the submit button wasn't found
      console.error("CF7 submit button not found.");
    }
  });

  document.addEventListener("click", function (e) {
    // Check if the click is on your custom submit button
    if (e.target === submitBtn || submitBtn.contains(e.target)) {
      // Insert CF7 spinner HTML into your button
      const spinnerHtml = '<span class="wpcf7-spinner"></span>'; // Update this with CF7's actual spinner HTML
      submitBtn.insertAdjacentHTML("beforeend", spinnerHtml);

      // Trigger CF7 form submission here, e.g., by clicking the hidden CF7 submit button
      // document.querySelector('.wpcf7-submit').click();
    }
  });

  document.addEventListener(
    "wpcf7mailsent",
    function (event) {
      header.classList.remove("form-opened");
      submitBtn.removeChild(submitBtn.querySelector(".wpcf7-spinner"));
      submitBtn.classList.remove("form-submitting");
      submitBtn.disabled = false;
      document.querySelector(".doctors-dropdown__header__text").textContent =
        "Izaberite lekara"; // Reset dropdown text if needed
      filesSpan.textContent =
        "Dodajte sve nalaze koji lekaru mogu biti korisni";
      document.getElementById("custom-form").reset();
      document.querySelector("#contact-form-container form").reset();
      alert("Obrazac je uspešno poslat!");
    },
    false
  );

  document.addEventListener(
    "wpcf7invalid",
    function (event) {
      alert("Please fill in the required fields.");
      submitBtn.removeChild(submitBtn.querySelector(".wpcf7-spinner"));
      submitBtn.classList.remove("form-submitting");
      submitBtn.disabled = false;
    },
    false
  );

  document.addEventListener(
    "wpcf7spam",
    function (event) {
      alert("Spam detected!");
      submitBtn.removeChild(submitBtn.querySelector(".wpcf7-spinner"));
      submitBtn.classList.remove("form-submitting");
      submitBtn.disabled = false;
    },
    false
  );

  document.addEventListener(
    "wpcf7mailfailed",
    function (event) {
      alert("Slanje nije uspelo. Molim vas, pokušajte ponovo.");
      submitBtn.removeChild(submitBtn.querySelector(".wpcf7-spinner"));
      submitBtn.classList.remove("form-submitting");
      submitBtn.disabled = false;
    },
    false
  );

  function showError(field, message) {
    const errorElement = document.getElementById(field + "-error");
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.classList.remove("hidden");
    }
  }

  function hideError(field) {
    const errorElement = document.getElementById(field + "-error");
    if (errorElement) {
      errorElement.classList.add("hidden");
    }
  }
}
