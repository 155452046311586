export function initAccordions() {
  const accordions = document.querySelectorAll(".accordion-wrapper");

  accordions?.forEach((accordion) => {
    const nextBtn = accordion.querySelector(".next-btn");
    const closeBtn = accordion.querySelector(".close-btn");
    const content = accordion.querySelector(".accordion-content");

    if (
      content &&
      (!content.classList.contains("responsive") || window.innerWidth < 744)
    ) {
      nextBtn?.addEventListener("click", function () {
        content.classList.add("active");
        accordion.querySelector(".accordion")?.classList.add("active");
        content.style.maxHeight = content.scrollHeight + "px";
      });

      closeBtn?.addEventListener("click", function () {
        content.classList.remove("active");
        accordion.querySelector(".accordion")?.classList.remove("active");
        content.style.maxHeight = 0;
      });
    }
  });

  const specialAccordions = document.querySelectorAll(
    ".accordion-wrapper.special"
  );
  const sharedContent = document.getElementById("sharedContent");

  specialAccordions.forEach((accordionWrapper) => {
    const accordion = accordionWrapper.querySelector(".accordion");
    const nextBtn = accordionWrapper.querySelector(".next-btn");
    const closeBtn = accordionWrapper.querySelector(".close-btn");
    const contentId = accordionWrapper.dataset.contentId;

    const toggle = (isOpen) => {
      if (window.innerWidth > 744) {
        toggleSpecialContent(accordion, contentId, isOpen, specialAccordions);
      }
    };

    nextBtn.addEventListener("click", () => toggle(true));
    closeBtn.addEventListener("click", () => toggle(false));
  });

  const toggleSpecialContent = (
    clickedHeader,
    contentId,
    isOpen,
    allAccordions
  ) => {
    const contents = document.querySelectorAll("#sharedContent > *");

    // Deactivate all accordions and their contents, except the clicked one
    allAccordions.forEach((accordion) => {
      const header = accordion.querySelector(".accordion");
      if (header !== clickedHeader) {
        header.classList.remove("active");
      }
    });

    contents.forEach((content) => {
      if (content.id !== contentId) {
        content.classList.remove("active");
        content.style.display = "none"; // Hide other contents immediately
      }
    });

    // Handle the clicked accordion and its content
    if (isOpen) {
      clickedHeader.classList.add("active"); // Activate the clicked header
      const activeContent = document.getElementById(contentId);
      if (activeContent) {
        activeContent.classList.add("active");
        activeContent.style.display = "block"; // Show the clicked content
        sharedContent.style.maxHeight = `${activeContent.scrollHeight}px`; // Adjust maxHeight for smooth opening
      }
    } else {
      clickedHeader.classList.remove("active"); // Deactivate the clicked header if it was closing
      sharedContent.style.maxHeight = "0"; // Start closing transition

      // Use 'transitionend' event to hide the content after transition
      sharedContent.addEventListener(
        "transitionend",
        function handleTransitionEnd() {
          if (!sharedContent.classList.contains("active")) {
            const activeContent = document.getElementById(contentId);
            activeContent.style.display = "none"; // Hide the clicked content after transition
          }
          sharedContent.removeEventListener(
            "transitionend",
            handleTransitionEnd
          );
        },
        { once: true }
      );
    }

    // Adjust the sharedContent's active class based on whether any content is active
    sharedContent.classList.toggle(
      "active",
      isOpen || !!document.querySelector("#sharedContent > div.active")
    );
  };
}
