export function initAjaxSearch() {
  jQuery(document).ready(function ($) {
    $(".search-button").on("click", function () {
      const searchValue = $("#pretraga").val();

      const parent = $(this).parent();

      parent.addClass("searching");

      $.ajax({
        type: "POST",
        url: myAjax.ajaxurl,
        data: {
          action: "search_site",
          search: searchValue,
          nonce: myAjax.nonce,
        },
        success: function (response) {
          $(".hidden-menu__menu-wrapper").addClass("search");
          $(".hidden-menu__menu-wrapper__search-results__content").html(
            response
          );

          parent.removeClass("searching");
        },
      });
    });
  });
}
